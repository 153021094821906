export const PANDO_API_URL = (function getAPIURL() {
  if (window.location.hostname === 'tools.pando.com') return 'https://tools-api.pando.com';

  const fromRender = window.location.hostname.match(/^pando-tools-pr-([0-9]+)\.onrender\.com$/);
  if (fromRender) return `https://pando-tools-api-pr-${fromRender[1]}.onrender.com`;

  return 'http://localhost:3000';
}());

export const ENVIRONMENT = {
  'tools.pando.com': 'production',
  'pando-tools.onrender.com': 'staging',
}[window.location.hostname] || 'development';

export const SENTRY = {
  TRACING_ORIGINS: [
    'pando-tools-pr-*.onrender.com',
    'tools.pando/*',
  ],
  DSN: 'dc8627e884cf407bb3c31c561c7695e2@o1229261',
  PROJECT_ID: '4505567693504512',
};

export const VITE_GIT_BRANCH = import.meta.env.VITE_GIT_BRANCH || 'local';

export const VITE_GIT_COMMIT = import.meta.env.VITE_GIT_COMMIT || 'unreleased';
