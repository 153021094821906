import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  /* app */
  {
    name: 'root',
    path: '/',
    component: () => import('./App.vue'),
    children: [
      {
        name: 'home',
        path: '',
        component: () => import('./home/index.vue'),
      },
      /* manager dashboard */
      {
        name: 'levels',
        path: 'levels',
        component: () => import('./levels/index.vue'),
        meta: { title: 'Company Levels Tool' },
        children: [
          {
            name: 'form',
            path: '',
            component: () => import('./levels/form.vue'),
            meta: { title: 'Form' },
          },
          {
            name: 'framework 9',
            path: 'framework-9',
            component: () => import('./levels/framework9/index.vue'),
            meta: { title: 'Framework 9' },
            children: [
              {
                name: 'framework 9 unique',
                path: ':key',
                component: () => import('./levels/framework9/index.vue'),
                meta: { title: 'Framework 9' },
              },
            ],
          },
          {
            name: 'framework 11',
            path: 'framework-11',
            component: () => import('./levels/framework11/index.vue'),
            meta: { title: 'Framework 11' },
            children: [
              {
                name: 'framework 11 unique',
                path: ':key',
                component: () => import('./levels/framework11/index.vue'),
                meta: { title: 'Framework 11' },
              },
            ],
          },
          {
            name: 'framework 15',
            path: 'framework-15',
            component: () => import('./levels/framework15/index.vue'),
            meta: { title: 'Framework 15' },
            children: [
              {
                name: 'framework 15 unique',
                path: ':key',
                component: () => import('./levels/framework15/index.vue'),
                meta: { title: 'Framework 15' },
              },
            ],
          },
        ],
      },
    ],

  },
  // redirect for unmatched routes
  { path: '/:pathMatch(.*)*', redirect: '/' },
];

export default routes;
