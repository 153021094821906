<script setup lang="ts">
</script>

<template>
  <div class="pando-tools">
    <nav class="pando-tools__app-header">
      <a href="https://pando.com">
        <img
          alt="Pando logo"
          src="/assets/pando-wordmark.svg"
        >
      </a>
    </nav>
    <RouterView />
  </div>
</template>

<style lang="postcss">
@import "./styles/common/pando-default.css";
@import "./styles/templates/visually-hidden.css";

& main {
  padding-top: 82px;
  height: 100%;
  margin: auto;
  overflow-x: hidden;
}
nav.pando-tools__app-header {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  z-index: 2;
}

.pando-tools {
}

</style>
